.work {
    padding-top: 128px;
}

.title {
    font-family: SF Pro Display;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;
    padding-bottom: 32px;
}

.workRow {
    display: flex;
    flex-direction: row;
    gap: 16px
}

.workColumn {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

@media only screen and (max-width: 850px) {
    .work {
        padding-top: 88px;
    }

    .workRow {
        flex-direction: column;
        gap: 16px;
    }
}