.buttonActive {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 12px 16px;

    color: #FFF;

    background-color: #000080;

    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;

    max-height: 48px;

    cursor: pointer;
}

.buttonInactive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    max-height: 48px;

    color: black;

    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
}