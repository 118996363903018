.footer {
    background-color: black;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #fff;
    padding: 12px 64px;

    font-family: SF Pro Display;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

@media only screen and (max-width: 390px) {
    .footer {
        padding: 12px 0px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}