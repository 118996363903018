.description {
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    font-family: SF Pro Display;
    font-size: 96px;
    font-weight: 400;
    line-height: 86.4px;
    letter-spacing: -0.01em;
    text-align: left;
}

.strongy {
    font-family: SF Pro Display;
    font-size: 96px;
    font-weight: 900;
    line-height: 86.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #000080;
}

.text {
    font-family: SF Pro Text;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
}

@media only screen and (max-width: 620px) {
    .description {
        padding-top: 128px;
    }

    .title {
        font-family: SF Pro Display;
        font-size: 40px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    .strongy {
        font-family: SF Pro Display;
        font-size: 40px;
        font-weight: 900;
        line-height: 36px;
        letter-spacing: -0.01em;
        text-align: left;
    }

    .text {
        font-family: SF Pro Text;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.015em;
        text-align: left;
    }
}