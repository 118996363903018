.app {
  padding: 0 64px;
  height: fit-content;
  // overflow: hidden;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100;
}

@media only screen and (max-width: 620px) { 
  .app {
    padding: 0 16px;
  }
}