.header {
    width: calc(100vw - 128px);
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
    height: 72px;
    background-color: white;
    top: 0;
    z-index: 1000;
    padding-right: 64px;
    padding-left: 64px;
}

.logo {
    height: 32px;
}

.buttons {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 620px) { 
    .header{
        width: calc(100vw - 32px);
        padding-right: 16px;
        padding-left: 16px;
    }
  }