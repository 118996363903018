.contact {
    display: flex;
    flex-direction: row;
    height: 550px;
    width: 100%;
    padding-top: 128px;
    padding-bottom: 176px;
}

.firstHalf {
    font-family: SF Pro Display;
    font-size: 96px;
    font-weight: 700;
    line-height: 86.4px;
    letter-spacing: -0.01em;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: #FFFFFF;
    padding: 48px 64px;

    width: 50%;
    background-color: #000080;

    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.secoundHalf {
    background-color: #F0F0FB;

    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 50%;
    gap: 16px;

    padding: 48px 64px;
}

.inputBox {
    width: 100%;
    padding: 12px 16px;
    gap: 8px;
    border-radius: 8px;
    border-width: 1px;
    border-color: #C4C4C9;
    outline: none;
    // opacity: 1;
}

.inputBox:focus {
    border-color: #000080;
    border-width: 2px;
}

.field {
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.button {
    width: 100%;
    background: #000080;
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
}

.buttonText {
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #FFFFFF;
}

.detaliu {
    display: flex;
    justify-content: space-between;
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    position: relative;
}

.line {
    border-bottom: 1px solid #3A3AA5;
    margin-bottom: 7px;
}

@media only screen and (max-width: 850px) {
    .contact {
        flex-direction: column;
        padding-bottom: 300px;
    }

    .firstHalf {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 0px;

        font-size: 40px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: -0.01em;

        padding: 24px 16px;

        gap: 32px;

        width: auto;
    }

    .secoundHalf {
        border-top-right-radius: 0px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        padding: 24px 16px;

        width: auto;
    }

    .field {
        font-family: SF Pro Text;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .detaliu {
        font-family: SF Pro Text;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }

    .inputBox {
        width: auto;
    }

    .button {
        width: auto;
    }
}