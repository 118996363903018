.workCard {
    display: flex;
    flex-direction: column;
    gap: 16px;
    cursor: pointer;
}

.img {
    object-fit: cover;
    max-width: 100%;
    max-height: 800px;
    border-radius: 32px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
}

.title {
    font-family: SF Pro Text;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.64px;
    text-align: left;
    color: black
}